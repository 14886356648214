
    import './styles.scoped.css';
    export default {
  "trigger-button-styles": "awsui_trigger-button-styles_lpshu_1kyee_145",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_lpshu_1kyee_168",
  "trigger": "awsui_trigger_lpshu_1kyee_145",
  "selected": "awsui_selected_lpshu_1kyee_212",
  "badge": "awsui_badge_lpshu_1kyee_232",
  "trigger-wrapper": "awsui_trigger-wrapper_lpshu_1kyee_236",
  "dot": "awsui_dot_lpshu_1kyee_244"
};
  