
    import './styles.scoped.css';
    export default {
  "flash-with-motion": "awsui_flash-with-motion_1q84n_ld8k0_157",
  "enter": "awsui_enter_1q84n_ld8k0_157",
  "flash-body": "awsui_flash-body_1q84n_ld8k0_171",
  "flash-message": "awsui_flash-message_1q84n_ld8k0_171",
  "flash-header": "awsui_flash-header_1q84n_ld8k0_171",
  "flash-content": "awsui_flash-content_1q84n_ld8k0_172",
  "action-button-wrapper": "awsui_action-button-wrapper_1q84n_ld8k0_173",
  "dismiss-button-wrapper": "awsui_dismiss-button-wrapper_1q84n_ld8k0_174",
  "flash-icon": "awsui_flash-icon_1q84n_ld8k0_197",
  "entering": "awsui_entering_1q84n_ld8k0_210",
  "entered": "awsui_entered_1q84n_ld8k0_231",
  "exiting": "awsui_exiting_1q84n_ld8k0_336",
  "stack": "awsui_stack_1q84n_ld8k0_370",
  "animation-running": "awsui_animation-running_1q84n_ld8k0_370",
  "item": "awsui_item_1q84n_ld8k0_370",
  "flash-list-item": "awsui_flash-list-item_1q84n_ld8k0_371",
  "notification-bar": "awsui_notification-bar_1q84n_ld8k0_372",
  "collapsed": "awsui_collapsed_1q84n_ld8k0_392",
  "animation-ready": "awsui_animation-ready_1q84n_ld8k0_392",
  "expanded-only": "awsui_expanded-only_1q84n_ld8k0_392",
  "expanded": "awsui_expanded_1q84n_ld8k0_392",
  "flash": "awsui_flash_1q84n_ld8k0_157",
  "collapsible": "awsui_collapsible_1q84n_ld8k0_448",
  "short-list": "awsui_short-list_1q84n_ld8k0_454",
  "visual-refresh": "awsui_visual-refresh_1q84n_ld8k0_454",
  "status": "awsui_status_1q84n_ld8k0_601",
  "header": "awsui_header_1q84n_ld8k0_601",
  "item-count": "awsui_item-count_1q84n_ld8k0_602",
  "button": "awsui_button_1q84n_ld8k0_603",
  "type-count": "awsui_type-count_1q84n_ld8k0_636",
  "count-number": "awsui_count-number_1q84n_ld8k0_636",
  "icon": "awsui_icon_1q84n_ld8k0_670",
  "floating": "awsui_floating_1q84n_ld8k0_711",
  "flashbar": "awsui_flashbar_1q84n_ld8k0_715",
  "initial-hidden": "awsui_initial-hidden_1q84n_ld8k0_773",
  "flash-list": "awsui_flash-list_1q84n_ld8k0_371",
  "flash-focus-container": "awsui_flash-focus-container_1q84n_ld8k0_795",
  "flash-text": "awsui_flash-text_1q84n_ld8k0_825",
  "hidden": "awsui_hidden_1q84n_ld8k0_844",
  "header-replacement": "awsui_header-replacement_1q84n_ld8k0_849",
  "content-replacement": "awsui_content-replacement_1q84n_ld8k0_854",
  "dismiss-button": "awsui_dismiss-button_1q84n_ld8k0_174",
  "breakpoint-default": "awsui_breakpoint-default_1q84n_ld8k0_873",
  "action-button": "awsui_action-button_1q84n_ld8k0_173",
  "action-slot": "awsui_action-slot_1q84n_ld8k0_883",
  "flash-type-success": "awsui_flash-type-success_1q84n_ld8k0_887",
  "flash-type-error": "awsui_flash-type-error_1q84n_ld8k0_891",
  "flash-type-info": "awsui_flash-type-info_1q84n_ld8k0_895",
  "flash-type-in-progress": "awsui_flash-type-in-progress_1q84n_ld8k0_896",
  "flash-type-warning": "awsui_flash-type-warning_1q84n_ld8k0_900"
};
  