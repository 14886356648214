import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadTable } from '../common/RadTable'
import { RadSelect } from '../common/RadSelect'

export function AgentList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tagIds') ?? '')
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState('ascending')
  const [selectedType, setSelectedType] = useState('all')
  const [selectedStatus, setSelectedStatus] = useState(searchParams.get('status') ?? 'Active')
  const { data: agents, count } = useGet(
    `/api/agent?${new URLSearchParams({
      search: searchText ?? '',
      limit: pageLength.toString(),
      offset: ((currentPageIndex - 1) * pageLength).toString(),
      type: selectedType,
      status: selectedStatus,
      sort: sortColumn,
      direction: sortDirection,
      ...(tagIds ? { tagIds } : {})
    }).toString()}`
  )

  const { data: tags } = useGet('/api/tag?limit=2000')

  if (
    agents != null &&
    tags != null
  ) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })
    const typeOptions = [
      { label: 'All', value: 'all' },
      { label: 'Processor', value: 'Processor' },
      { label: 'Chatbot', value: 'Chatbot' },
    ]

    const statusOptions = [
      { label: 'Active', value: 'Active' },
      { label: 'Archive', value: 'Archive' },
      { label: 'All', value: 'all' }
    ]

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/agent/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Agents
          </RadHeader>
        }
        content={
          <RadTable
            columnDefinitions={[
              {
                id: 'id',
                header: 'ID',
                cell: item => item.id
              },
              {
                id: 'name',
                header: 'Name',
                cell: item => (
                  <RadLink href={`/agent/${item.id}`}>{item.name}</RadLink>
                )
              },
              {
                id: 'type',
                header: 'Type',
                cell: item => item.type?.name ?? '-'
              },
              {
                id: 'tags',
                header: 'Tags',
                cell: item => item.tags.length > 0 
                  ? item.tags.map(x => x.tag.name).join(', ') 
                  : '-'
              }
            ]}
            items={agents}
            loading={agents == null}
            loadingText="Loading agents"
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, s: 3 } },
                  { colspan: { default: 12, s: 3 } },
                  { colspan: { default: 12, s: 3 } },
                  { colspan: { default: 12, s: 3 } }
                ]}
              >
                <RadTextFilter
  filteringPlaceholder='Search by name'  // Updated to reflect current functionality
  filteringAriaLabel='Search agents'
  filteringText={filteringText}
  onChange={({ detail }) => setFilteringText(detail.filteringText)}
  onDelayedChange={({ detail }) => {
    setSearchText(detail.filteringText)
    setCurrentPageIndex(1)
    searchParams.set('page', 1)
    if (detail.filteringText) {
      searchParams.set('search', detail.filteringText)
    } else {
      searchParams.delete('search')
    }
    setSearchParams(searchParams)
  }}
/>
                <RadMultiselect
                  filteringType='auto'
                  selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    if (ids === '') {
                      searchParams.delete('tagIds')
                    } else {
                      searchParams.set('tagIds', ids)
                    }
                    setTagIds(ids)
                    searchParams.set('page', 1)
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={tagOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose tags'
                  keepOpen={false}
                />
                <RadSelect
                  selectedOption={typeOptions.find(x => x.value === selectedType)}
                  onChange={({ detail }) => {
                    setSelectedType(detail.selectedOption.value)
                    // Update API call with type filter
                  }}
                  options={typeOptions}
                  placeholder="Select type"
                />
                <RadSelect
                  selectedOption={statusOptions.find(x => x.value === selectedStatus)}
                  onChange={({ detail }) => {
                    setSelectedStatus(detail.selectedOption.value)
                  }}
                  options={statusOptions}
                  placeholder="Select status"
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
