
    import './styles.scoped.css';
    export default {
  "block-body-scroll": "awsui_block-body-scroll_19d8l_1e6a2_153",
  "mobile-bar": "awsui_mobile-bar_19d8l_1e6a2_157",
  "mobile-bar-breadcrumbs": "awsui_mobile-bar-breadcrumbs_19d8l_1e6a2_170",
  "mobile-toggle": "awsui_mobile-toggle_19d8l_1e6a2_177",
  "mobile-toggle-type-navigation": "awsui_mobile-toggle-type-navigation_19d8l_1e6a2_186",
  "mobile-toggle-type-tools": "awsui_mobile-toggle-type-tools_19d8l_1e6a2_189",
  "mobile-toggle-type-drawer": "awsui_mobile-toggle-type-drawer_19d8l_1e6a2_189",
  "drawers-container": "awsui_drawers-container_19d8l_1e6a2_196"
};
  